import React, { useEffect, useState } from 'react'
import style from './About.module.scss'
import NavigationMenu from '../../components/NavigationMenu/NavigationMenu'
import Footer from '../../components/Footer/Footer'
import { Trans } from 'react-i18next';
import { sendVerificationTokenRequest } from '../../utils/api/sendApiRequest';
import { BASE_URL } from '../../utils/api/URL';
import { t } from 'i18next';

export default function About() {
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        const getAccess = async () => {
            const data = await sendVerificationTokenRequest(BASE_URL + '/token/verification');
            if (data && data.verify) {
                setAuth(data.user);
            }
        }
        getAccess();
    }, []);

    return (
        <div className={style.wrapper}>
            <NavigationMenu page={t('components.NavigationMenu.About')} to={auth ? `/${auth.role}` : '/'} />
            <main className={style.about}>
                <div className={style.container}>
                    <p className={style.text}><Trans
                        i18nKey={'page.About.tel'}
                        components={{
                            accent: <a className={style.accent} href="tel:+79257726040" />
                        }}
                    /></p>
                    <p className={style.text}><Trans
                        i18nKey={'page.About.address'}
                        components={{
                            accent: <span className={style.accent} />
                        }}
                    />
                    </p>
                </div>
            </main >
            <Footer />
        </div >
    )
}
